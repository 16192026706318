$(function(){
  $('#spOnly-toggler').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $('#menu-overlay').css('display','none');
      $('.sp-menu').removeClass('overlay-active');
    } else {
      $(this).addClass('active');
      $('#menu-overlay').css('display','block');
      $('.sp-menu').addClass('overlay-active');
    }
  });
});

$(function(){
  $('.dropdown-toggle').on('click',function(){
    if($('.dropdown-menu').hasClass('open')){
      $('.dropdown-menu').removeClass('open');
      $('.dropdown-menu').css('display','none');
    } else {
      $('.dropdown-menu').addClass('open');
      $('.dropdown-menu').css('display','block');
    }
  });
});
